<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span class="text-h3">
          {{ readOnly ? $t("view_vehicle") : $t("edit") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="editVehicle.vehicle_customer"
                dense
                clearable
                :items="oemCustomersList"
                :loading="isLoadingOemCustomers"
                item-text="name"
                item-value="id"
                :label="isFleet ? $t('vehicle_driver') : $t('vehicle_customer')"
                :disabled="readOnly"
                @change="onChangeVehicleCustomer"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="editVehicle.test_customers"
                dense
                :items="oemTestCustomersList"
                :loading="isLoadingOemCustomers"
                item-text="name"
                item-value="id"
                :label="isFleet ? $t('test_driver') : $t('test_customers')"
                :disabled="readOnly || !editVehicle.vehicle_customer"
                :rules="[rules.required]"
                multiple
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>*indicates required field</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleEdit()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="isLoadingSave"
          @click="assignCustomers(editVehicle)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isFleet: {
      type: Boolean,
      default() {
        return false;
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    editVehicle: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      oemCustomersList: "oemCustomers/customerLists",
      oemTestCustomersList: "oemCustomers/activeTestCustomersList",
      isLoadingOemCustomers: "oemCustomers/getIsLoadingOemCustomers",
    }),

    isMdModelHasFixedBatterySlot() {
      var mdModel = this.mdModelsList.find(
        (item) => item.id === this.editVehicle.md_model_id
      );

      if (mdModel) {
        return mdModel.battery_slot_type == "fixed";
      }

      return false;
    },
  },

  watch: {
    isVisibleDialog: {
      handler: function () {
        this.loadData();
      },
      deep: true,
    },
  },

  async mounted() {
    // this.loadData();
    // let payload = {
    //   tenant_id: this.authUser.isAdmin ? this.editVehicle.tenant_id : null,
    // };
    // let params = [
    //   this.editVehicle.vehicle_customer,
    //   ...this.editVehicle.test_customers,
    // ];
    // payload.vehicle_to = params == 0 ? null : params;
    // if (this.editVehicle.oem_b2b_id && this.authUser.user_nature !== 'fleet') {
    //   payload.oem_b2b_id = this.editVehicle.oem_b2b_id ?? null;
    // } else {
    //   payload.vehicle_assigned = params.length > 0 ? false : null;
    // }
    // this.$store.dispatch("oemCustomers/list", payload);
    if (this.authUser.user_nature == "fleet") {
      let payload = {
        tenant_id: this.authUser.isAdmin ? this.editVehicle.tenant_id : null,
      };
      let params = [
        this.editVehicle.vehicle_customer,
        ...this.editVehicle.test_customers,
      ];
      payload.vehicle_to = params == 0 ? null : params;

      if (this.editVehicle.oem_b2b_id) {
        payload.oem_b2b_id = this.editVehicle.oem_b2b_id ?? null;
      } else {
        payload.vehicle_assigned = params.length > 0 ? false : null;
      }

      // this.$store.dispatch("oemCustomers/list", payload);
    }
  },

  methods: {
    async loadData() {
      let payload = {
        tenant_id: this.authUser.isAdmin ? this.editVehicle.tenant_id : null,
      };
      let params = [
        this.editVehicle.vehicle_customer,
        ...this.editVehicle.test_customers,
      ];

      payload.vehicle_to = params == 0 ? null : params;

      if (
        this.editVehicle.oem_b2b_id &&
        this.authUser.user_nature !== "fleet"
      ) {
        payload.oem_b2b_id = this.editVehicle.oem_b2b_id ?? null;
      } else {
        payload.vehicle_assigned = params.length > 0 ? false : null;
      }

      this.$store.dispatch("oemCustomers/list", payload);
    },
    async assignCustomers(editVehicle) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("vehicles/assignCustomers", editVehicle)
          .then(async (vehicle) => {
            if (vehicle.emailSent == false) {
              this.$store.dispatch(
                "alerts/info",
                this.$t("customer_assigned_email_not_sent")
              );
            } else {
              this.$store.dispatch(
                "alerts/success",
                this.$t("updated_successfully")
              );
            }
            this.isLoadingSave = false;
            await this.$store.dispatch("oemCustomers/list", {
              vehicle_assigned: false,
            });
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    onChangeVehicleCustomer(v) {
      if (!v) {
        this.editVehicle.test_customers = [];
      }
    },
  },
};
</script>
